import NavBar from "../../components/NavBar";
import InvasionPlan from "../../components/InvasionPlan";

import "./index.css";
import "react-accessible-accordion/dist/fancy-example.css";
import LandingFront from "../../components/LandingFront";


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Footer from "../../components/Footer";
function Home() {
  return (
    <div className="FAQ">
      <div className="container-wrap">
        <NavBar />
        <LandingFront />
        <div className="">
          <div className="">
            <div className="topmargin"></div>

            <h2 className="new-section-text">FAQ</h2>
            <div className="">
              {/* <div className="divider"></div> */}

              <div className="container-faq">
                <div className="faq-inner-container">
                  <Accordion>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          Is the Metavaders team affiliated with Animetas?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          No - while we own and are supporters of Animetas NFTs,
                          we are independent and do not have any affiliation
                          with the Animetas project. 
                          <br />
                          <br />
                          We created Metavaders as our way of expanding the
                          Animetas ecosystem and increasing the types of
                          interactions that can be made possible. We plan on
                          continuously developing on Metavaders, and eventually
                          be able to connect to and interact with the ecosystems
                          of other metaverses in the future.
                          <br />
                          <br />
                          It is our core belief that NFTs should be fun and
                          interactive, and is something that we want to own to
                          interact with regularly. 
                          <br />
                          <br />
                          As we are also big believers of the Play-to-Earn
                          model, you can expect a very different set of
                          interactions with Metavaders than what is currently
                          available in the existing Animetas metaverse.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          How much will it cost to mint a Metavader?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          The launch price of each Metavader will be 0.07 ETH
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          When are Metavaders launching?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Please check our Discord for the latest information on our launch date as the information there will be most up to date.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                           Will we be offering a Presale?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Yes, due to community feedback we will be offering a presale a few hours ahead of the official launch. This is to allow our early supporters a chance to get their Metavaders before the crowd. Don’t worry if you don’t make it as there will be a limit on how many mints are available for Presale to ensure anyone in the main launch will get a fair shot at getting a Metavader. For more information on Presale and how you can join please check our discord here.                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          How do I buy a Metavader?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          You can buy them at the primary sale on our website at
                          launch. The secondary market will be on OpenSea.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          What is the Vader Vault?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          The Vader Vault is where the captured NFTs will be
                          stored. Metavaders that have participated in growing
                          the vault will get the honor of getting fractional
                          ownership of all NFTs within it. This means they will
                          be allowed to vote on the fate of the captured NFTs as
                          well as possibly be granted other special privileges.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          What will royalties and fees be?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Royalty fees will be 5% of a secondary sale. Part of
                          the received royalty will be used towards growing the
                          community and future development.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {/* <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          Are the drones NFTs airdrop free?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Yes - all Metavader owners will have their drones
                          airdropped to their wallets when the collection has
                          been created and announced.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem> */}
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          What is the smart contract address of the Metavaders?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          We will post the smart contract address at a time closer to launch for participants to review the contract
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          What are Metavaders commercial rights?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Please refer to our ‘
                          <a href="/terms-and-conditions" style={{ color: "#FFF" }}>
                            Terms and Conditions
                          </a>
                          ’ page
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          What Does Activating Your Metavaders Do?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Activating Metavaders will provide it with a new
                          “activated” look, as well as provide it with
                          additional non-visible traits (such as “battle power”)
                          that will be used in the future. This can be reversed
                          (i.e. de-activated).
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          How Do You Activate Your Metavaders?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Currently this is still in development and will launch
                          shortly after the drop of our Metavaders (refer to
                          roadmap for exact timing). In short, to activate your
                          Metavader you will first need to own the NFT of the
                          metaverse that we are invading (in this case,
                          Animetas), and utilize the activation command to
                          change your metavader's appearance (a small amount of
                          gas will be required for the process).
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          What Does Transforming Metavaders Do?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Details are still being ironed out, but transforming
                          your Metavader will forever alter its look and traits.
                          This will allow it to become part of the Metavader
                          Council, thereby giving it certain privileges and
                          power. More details will be released, so please stay
                          tuned and refer to our roadmap.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          How Do You Transform Your Metavader?{" "}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          Currently this is still in development and will launch
                          after the drop of our Metavaders (refer to roadmap for
                          exact timing). In short, by completing certain
                          actions, you will be able to forever transform your
                          Metavader into an upgraded form (cooler art!).
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{ backgroundColor: "#1E1E1E" }}
                        >
                          Who Is The Team Behind Metavader?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          className="normal-text"
                          style={{ textAlign: "start" }}
                        >
                          We are currently a team of 4 that are mainly made up
                          of crypto enthusiasts. Responsibilities generally fall
                          under: front end development (website), back end
                          development (smart contracts), project
                          coordination/community management and artwork
                          creation. We intend on reinvesting a portion of our
                          sales proceeds to expand the team and further develop
                          our ecosystem. We will turn our vision of an
                          interconnected network of metaverses into a reality!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator"></div>
        <InvasionPlan />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
