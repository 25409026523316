import "./style.css";

export const TimeLineItem = ({ number, list, arrow_hide="inherit" }) => {
  let ul = [];
  for (let i =0; i < list.length; i++) {
    ul.push(<li>{list[i]}</li>);
  } 

  return (
    <div className="timeline-v2">
      <div className="timebox">
        <div className="number-container">
          <p className="percent-number">{number}<br />
            <span style={{ fontSize: "72px", display:arrow_hide }}>🠗</span>
          </p>
        </div>
      </div>
      <div className="description-box">
        <ul className="description-text">
          {ul}
        </ul>
        {/* <p className="roadmap-description">{description}</p> */}
      </div>
    </div>
  );
};
export default TimeLineItem;
