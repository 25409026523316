import Web3 from "web3";

const infuraProjectId = process.env.REACT_APP_infuraProjectId;
const contractABI = require("./contracts/Metavaders.json"); // Make sure to upload correct ABI
const contractAddress = "0x85A44587136C551c7FDF1f3e99dC68BDe1BA3A2b"; // Update to contract address

const mintContractABI = require("./contracts/Metavaders_Mint.json"); // Make sure to upload correct ABI
const mintContractAddress = "0xcf73cABE3B589Ffc8bD0677B01526Ba2eec613AD"; // Update to contract address

var web3 = new Web3(`https://mainnet.infura.io/v3/${infuraProjectId}`);



export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Wallet Connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Wallet Connected",
        };
      } else {
        return {
          address: "",
          status: '🦊 Connect to Metamask using the "Connect Wallet" button above',
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const checkSupply = async() => {
  window.contract = await new web3.eth.Contract(contractABI['abi'], contractAddress);
  let remainingSupply = await window.contract.methods.remainingSupply().call();
  return remainingSupply;
}

export const checkIsWhitelisted = async() => {
  window.contract = await new web3.eth.Contract(contractABI['abi'], mintContractAddress);
  let isWhitelisted = await window.contract.methods.isWhitelisted().call();
  return isWhitelisted;
}


export const mintNFT = async (quantity, price) => {
  if (!window.ethereum.selectedAddress) {
    return {
      success: false,
      status: '🦊 Connect to Metamask using the "Connect Wallet" button above',
    }
  }

  if (quantity == 0) {
    return {
      success: false,
      status: "❗ Please make sure quantity is greater than 0.",
    };
  }

  // For Implementing a block on time
  var blockNumber = await web3.eth.getBlockNumber();
  var timestamp = (await web3.eth.getBlock(blockNumber)).timestamp;

  let mintContract = await new web3.eth.Contract(mintContractABI['abi'], mintContractAddress);
  let mvaderContract = await new web3.eth.Contract(contractABI['abi'], contractAddress);

  var isPaused = await mintContract.methods._paused().call();

  var launchTime = 1633363200; // Corresponds to launch date: Monday, October 4, 2021 4:00:00 PM UTC
  // var launchTime = 0; // For testing
  if (isPaused) {
    return {
      success: false,
      status: "😥 Minting Has Not Yet Begun."
    }
  } else if (timestamp < launchTime)  { 
      return {
      success: false,
      status: "😥 Minting Has Not Yet Begun. Starts at UNIX Time: " + launchTime,
    }
  }

  var remainingSupply = await mvaderContract.methods.remainingSupply().call();
  if (remainingSupply < 1)  {
    return {
    success: false,
    status: "😥 No more supply left",
  }}


  var total = quantity*price;
  total = total.toString();
  total = web3.utils.toWei(total,'ether');
  total = web3.utils.numberToHex(total);
  const transactionParameters = {
    from: window.ethereum.selectedAddress, // must match user's active address.
    to: mintContractAddress, // Required except during contract publications.
    value: total,
    data: mintContract.methods.public_mint(quantity).encodeABI(),
  };
  // console.log(transactionParameters);

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status: (
        <span>
        ✅ Check out your transaction on Etherscan: {" "}
        <a target="_blank" rel="noreferrer" href={`https://etherscan.io/tx/` + txHash}>
          https://etherscan.io/tx/{txHash}
        </a>
      </span>

      )
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const presaleMintNFT = async (quantity, price) => {
  const presale_limit =   1000;

  if (quantity == 0) {
    return {
      success: false,
      status: "❗ Please make sure quantity is greater than 0.",
    };
  }

  let mintContract = await new web3.eth.Contract(mintContractABI['abi'], mintContractAddress);
  let mvaderContract = await new web3.eth.Contract(contractABI['abi'], contractAddress);

  var isPaused = await mintContract.methods._paused().call();
  if (isPaused)  {
    return {
    success: false,
    status: "😥 Minting Has Not Yet Begun",
  }}

  var isWhitelisted = await mintContract.methods.isWhitelisted(window.ethereum.selectedAddress).call();
  if (!isWhitelisted)  { 
    return {
    success: false,
    status: "😥 You are not on Whitelist",
  }}

  var totalSupply = await mvaderContract.methods.totalSupply().call();
  if (totalSupply > presale_limit)  {
    return {
    success: false,
    status: "😥 Presale limit has been reached. Please wait for main sale.",
  }}


  var total = quantity*price;
  total = total.toString();
  total = web3.utils.toWei(total,'ether');
  total = web3.utils.numberToHex(total);
  const transactionParameters = {
    from: window.ethereum.selectedAddress, // must match user's active address.
    to: mintContractAddress, // Required except during contract publications.
    value: total,
    data: mintContract.methods.presale_mint(quantity).encodeABI(),
  };
  // console.log(transactionParameters);

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status: (
        <span>
        ✅ Check out your transaction on Etherscan: {" "}
        <a target="_blank" rel="noreferrer" href={`https://etherscan.io/tx/` + txHash}>
          https://etherscan.io/tx/{txHash}
        </a>
      </span>
      )
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};
