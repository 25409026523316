import "./style.css";

export const StoryLineItem = ({title, image, text, even_odd }) => {
  let story_line;
  if (even_odd == 'odd') {
    story_line = (          
      <div className="storyline-item-container storyline-item-container-odd">
            <h2 className="left">{title}</h2>
        <div className="storyline-item storyline-item-odd">
            <div className="left">
              <img className="storyline-image" src={image} />
            </div>
            <div className="right">
              <p>{text}</p>
            </div>
        </div>
      </div>
    )
  } else if (even_odd == 'even')  {
    story_line = (
      <div className="storyline-item-container storyline-item-container-even">
        <h2 className="right">{title}</h2>
        <div className="storyline-item storyline-item-even">
            <div className="left">
              <p>{text}</p>
            </div>
            <div className="right">
              <img className="storyline-image" src={image} />
            </div>
        </div>
      </div>
    )
  }

  return (
    <div className="storyline-item-wrapper">
      {story_line}
    </div>
  );
};
export default StoryLineItem;
