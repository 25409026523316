import "./style.css";

export const TimeLineItem = ({ number, description }) => {
  return (
    <div className="timeline">
      <div className="phasebox">
        <div className="phase-container">
          <p className="phase">Phase</p>
        </div>
        <div className="number-container">
          <p className="number">{number}</p>
        </div>
      </div>
      <div className="descriptionbox">
        <p className="roadmap-description">{description}</p>
      </div>
    </div>
  );
};
export default TimeLineItem;
