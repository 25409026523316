import React from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
function PrivacyPolicy() {
  return (
    <div>
      <div className="PrivacyPolicy">
        <div className="container-wrap">
          <NavBar />
          <div className="containerformetaverse">
            <div className="topmargin"></div>

            <p className="new-section-text">Privacy and Policy</p>
          </div>
          <div className="terms-content">
            <p className="template-text">
              This Privacy Policy describes how metavader.io (the “Site” or
              “we”) collects, uses, and discloses your Personal Information when
              you visit or make a purchase from the Site.
            </p>
            <h2 className="new-section-text">
              Collecting Personal Information
            </h2>
            <p className="template-text">
              When you visit the Site, we collect certain information about your
              device, your interaction with the Site, and information necessary
              to process your purchases. We may also collect additional
              information if you contact us for customer support. In this
              Privacy Policy, we refer to any information that can uniquely
              identify an individual (including the information below) as
              “Personal Information”. See the list below for more information
              about what Personal Information we collect and why.
            </p>
            <h4>Device information</h4>
            <p className="template-text">
              <li>
                Examples of Personal Information collected: version of web
                browser, IP address, time zone, cookie information, what sites
                or products you view, search terms, and how you interact with
                the Site.
              </li>
              <li>
                Purpose of collection: to load the Site accurately for you, and
                to perform analytics on Site usage to optimize our Site.
              </li>
              <li>
                Source of collection: Collected automatically when you access
                our Site using cookies, log files, web beacons, tags, or pixels.
              </li>
              <li>
                Disclosure for a business purpose: shared with our processor
                Shopify
              </li>
            </p>

            <h4>Order information</h4>
            <p className="template-text">
              <li>
                Examples of Personal Information collected: name, billing
                address, shipping address, payment information (including credit
                card numbers), email address, and phone number.
              </li>
              <li>
                Purpose of collection: to provide products or services to you to
                fulfill our contract, to process your payment information,
                arrange for shipping, and provide you with invoices and/or order
                confirmations, communicate with you, screen our orders for
                potential risk or fraud, and when in line with the preferences
                you have shared with us, provide you with information or
                advertising relating to our products or services.
              </li>
              <li>Source of collection: collected from you.</li>
              <li>
                Disclosure for a business purpose: shared with our processor
                Shopify
              </li>
            </p>
            <h4>Customer support information</h4>
            <p className="template-text">
              <li>
                Examples of Personal Information collected: email, phone number,
                Facebook profiles.
              </li>
              <li>Purpose of collection: to provide customer support.</li>
              <li>Source of collection: collected from you.</li>
              <li>
                Disclosure for a business purpose: shared with Facebook and
                other social media platforms for better understanding for
                customer support.
              </li>
            </p>
            <h2 className="new-section-text">Minors</h2>

            <p className="template-text">
              The Site is not intended for individuals under the age of 18. We
              do not intentionally collect Personal Information from children.
              If you are the parent or guardian and believe your child has
              provided us with Personal Information, please contact us at the
              address below to request deletion.
            </p>

            <h2 className="new-section-text">Sharing Personal Information</h2>

            <p className="template-text">
              We share your Personal Information with service providers to help
              us provide our services and fulfill our contracts with you, as
              described above. For example:
              <li>
                We use Shopify to power our online store. You can read more
                about how Shopify uses your Personal Information here:
                https://www.shopify.com/legal/privacy.
              </li>
              <li>
                We may share your Personal Information to comply with applicable
                laws and regulations, to respond to a subpoena, search warrant
                or other lawful request for information we receive, or to
                otherwise protect our rights.
              </li>
            </p>
            <h2 className="new-section-text">Behavioural Advertising</h2>
            <p className="template-text">
              As described above, we use your Personal Information to provide
              you with targeted advertisements or marketing communications we
              believe may be of interest to you. For example:
              <li>
                We use Google Analytics to help us understand how our customers
                use the Site. You can read more about how Google uses your
                Personal Information here:
                https://policies.google.com/privacy?hl=en.You can also opt-out
                of Google Analytics here:
                https://tools.google.com/dlpage/gaoptout.
              </li>
              <li>
                We share information about your use of the Site, your purchases,
                and your interaction with our ads on other websites with our
                advertising partners. We collect and share some of this
                information directly with our advertising partners, and in some
                cases through the use of cookies or other similar technologies
                (which you may consent to, depending on your location).
              </li>
              <br />
              For more information about how targeted advertising works, you can
              visit the Network Advertising Initiative’s (“NAI”) educational
              page at
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
              <br />
              <br />
              You can opt out of targeted advertising by:
              <br />
              <li>FACEBOOK - https://www.facebook.com/settings/?tab=ads</li>
              <li>GOOGLE - https://www.google.com/settings/ads/anonymous</li>
              <li>
                BING -
                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
              </li>
              <br />
              Additionally, you can opt out of some of these services by
              visiting the Digital Advertising Alliance’s opt-out portal at:
              http://optout.aboutads.info/.
            </p>

            <h2 className="new-section-text">Using Personal Information</h2>
            <p className="template-text">
              We use your personal Information to provide our services to you,
              which includes: offering products for sale, processing payments,
              shipping and fulfillment of your order, and keeping you up to date
              on new products, services, and offers.
            </p>
            <h2 className="new-section-text">Lawful basis</h2>
            <p className="template-text">
              Pursuant to the General Data Protection Regulation (“GDPR”), if
              you are a resident of the European Economic Area (“EEA”), we
              process your personal information under the following lawful
              bases:
              <li>Your consent;</li>
              <li>The performance of the contract between you and the Site;</li>
              <li>Compliance with our legal obligations;</li>
              <li>To protect your vital interests;</li>
              <li>To perform a task carried out in the public interest;</li>
              <li>
                For our legitimate interests, which do not override your
                fundamental rights and freedoms.
              </li>
            </p>

            <h2 className="new-section-text">Retention</h2>
            <p className="template-text">
              When you place an order through the Site, we will retain your
              Personal Information for our records unless and until you ask us
              to erase this information. For more information on your right of
              erasure, please see the ‘Your rights’ section below.
            </p>

            <h2 className="new-section-text">Automatic decision-making</h2>
            <p className="template-text">
              If you are a resident of the EEA, you have the right to object to
              processing based solely on automated decision-making (which
              includes profiling), when that decision-making has a legal effect
              on you or otherwise significantly affects you.
              <br />
              <br />
              We DO engage in fully automated decision-making that has a legal
              or otherwise significant effect using customer data.
              <br />
              <br />
              Our processor Shopify uses limited automated decision-making to
              prevent fraud that does not have a legal or otherwise significant
              effect on you.
              <br />
              <br />
              Services that include elements of automated decision-making
              include:
              <li>
                Temporary denylist of IP addresses associated with repeated
                failed transactions. This denylist persists for a small number
                of hours.
              </li>
              <li>
                Temporary denylist of credit cards associated with denylisted IP
                addresses. This denylist persists for a small number of days.
              </li>
            </p>

            <h2 className="new-section-text">Your rights</h2>
            <h4>GDPR</h4>

            <p className="template-text">
              If you are a resident of the EEA, you have the right to access the
              Personal Information we hold about you, to port it to a new
              service, and to ask that your Personal Information be corrected,
              updated, or erased. If you would like to exercise these rights,
              please contact us through the contact information below.
              <br />
              <br />
              Your Personal Information will be initially processed in Ireland
              and then will be transferred outside of Europe for storage and
              further processing, including to Canada and the United States. For
              more information on how data transfers comply with the GDPR, see
              Shopify’s GDPR Whitepaper: <br />
              <a href="https://help.shopify.com/en/manual/your-account/privacy/GDPR">
                https://help.shopify.com/en/manual/your-account/privacy/GDPR
              </a>
            </p>
            <h4>CCPA</h4>
            <p className="template-text">
              If you are a resident of California, you have the right to access
              the Personal Information we hold about you (also known as the
              ‘Right to Know’), to port it to a new service, and to ask that
              your Personal Information be corrected, updated, or erased. If you
              would like to exercise these rights, please contact us through the
              contact information below
              <br />
              <br />
              If you would like to designate an authorized agent to submit these
              requests on your behalf, please contact us at the address below.
            </p>
            <h2 className="new-section-text">Cookies</h2>
            <p className="template-text">
              A cookie is a small amount of information that’s downloaded to
              your computer or device when you visit our Site. We use a number
              of different cookies, including functional, performance,
              advertising, and social media or content cookies. Cookies make
              your browsing experience better by allowing the website to
              remember your actions and preferences (such as login and region
              selection). This means you don’t have to re-enter this information
              each time you return to the site or browse from one page to
              another. Cookies also provide information on how people use the
              website, for instance whether it’s their first time visiting or if
              they are a frequent visitor.
              <br />
              <br />
              The length of time that a cookie remains on your computer or
              mobile device depends on whether it is a “persistent” or “session”
              cookie. Session cookies last until you stop browsing and
              persistent cookies last until they expire or are deleted. Most of
              the cookies we use are persistent and will expire between 30
              minutes and two years from the date they are downloaded to your
              device.
              <br />
              <br />
              You can control and manage cookies in various ways. Please keep in
              mind that removing or blocking cookies can negatively impact your
              user experience and parts of our website may no longer be fully
              accessible.
              <br />
              <br />
              Most browsers automatically accept cookies, but you can choose
              whether or not to accept cookies through your browser controls,
              often found in your browser’s “Tools” or “Preferences” menu. For
              more information on how to modify your browser settings or how to
              block, manage or filter cookies can be found in your browser’s
              help file or through such sites as{" "}
              <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.
              <br />
              <br />
              Additionally, please note that blocking cookies may not completely
              prevent how we share information with third parties such as our
              advertising partners. To exercise your rights or opt-out of
              certain uses of your information by these parties, please follow
              the instructions in the “Behavioural Advertising” section above.
            </p>

            <h2 className="new-section-text">Do Not Track</h2>
            <p className="template-text">
              Please note that because there is no consistent industry
              understanding of how to respond to “Do Not Track” signals, we do
              not alter our data collection and usage practices when we detect
              such a signal from your browser.
            </p>

            <h2 className="new-section-text">Changes</h2>
            <p className="template-text">
              We may update this Privacy Policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal, or regulatory reasons.
            </p>
            <h2 className="new-section-text">Contact</h2>
            <p className="template-text">
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please
              contact us by e-mail at{" "}
              <a href="mailto:metavaders@gmail.com"> metavaders@gmail.com </a>
              or by mail using the details provided below:
              <br />
              <br />
              Unikorn Media Limited, Hong Kong SAR China
              <br />
              <br />
              Last updated: 31st October 2020
              <br />
              <br />
              If you are not satisfied with our response to your complaint, you
              have the right to lodge your complaint with the relevant data
              protection authority. You can contact your local data protection
              authority, or our supervisory authority.
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
