import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import CountdownTimer from "../../components/CountdownTimer";
import "./index.css";

import { IconButton, Input } from "@material-ui/core";

//Web3
import {
  connectWallet,
  getCurrentWalletConnected,
  presaleMintNFT,
  checkSupply,
} from "../../web3/interact.js";


function Presale() {
  //State variables
  // let currentTime = Date.now();
  // const launchDate = new Date('2021-09-1Z19:00:00') //Launch Date
  // let difference = launchDate - currentTime;
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [numberInput, setNumberInput] = useState(0);
  const [supply, setSupply] = useState("Calibrating");
  // const [psw, setPw] = useState("");
  // const [displayPw, setdisplayPw] = useState("none");
  // const [displayPresale, setdisplayPresale] = useState("inherit");
  // const [displayMint, setdisplayMint] = useState("inherit");
  // if (difference <= 0 && displayMint == "none") setdisplayMint("inherit")

  const price = 0.07;
  const maxQuantity = 20;
  const presaleQuota = 1500;

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);

    addWalletListener();
    updateSupply();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus('🦊 Connect to Metamask using the "Connect Wallet" button above');
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    const { success, status } = await presaleMintNFT(numberInput, price);
    setStatus(status);
    updateSupply();
  };

  const updateSupply = async () => {
    let result = await checkSupply();
    result = parseInt(result) + 200 - (10101 - presaleQuota); // Need to subtract the supply because not all of it is for presale
    setSupply(result);
  };
  // const pwSubmission = async () => {
  //   if(psw=="TEST") {
  //     setdisplayPw("none");
  //     setdisplayPresale("flex");
  //   } else {
  //     setStatus("Wrong Password: " + psw);
  //   }
  // }
  
  return (
    <div>
      {/* <div className="password-wrapper" style={{display: displayPw}}>
        <div className="password-container">
            <input type="password" placeholder="Enter Password" value={psw} onChange={(e) => setPw(String(e.target.value))} required></input>
            <button onClick={pwSubmission} >Access</button>
              <p id="status" style={{ color: "red" }}>
                {status}
              </p>
        </div>
      </div> */}
      <div className="Presale">
        <div className="container-wrap">
          <div className="onecol-container">
            <h2 className="new-section-text">PRESALE MINT</h2>
            <ul className="mint-ul">
              <li> You will need a Metamask wallet to mint. If you do not have one, you can get it <a href="https://metamask.io/download.html">here</a></li>
              <li> Presale date will start on Sunday, 3rd October 16:00 UTC and last until presale supplies are sold out.</li>
              <li> Each mint will cost {price} ETH + gas fees </li>
              <li> {maxQuantity} mints maximum per txn</li>
              <li> You can connect your wallet first but minting will only work once it is announced on discord</li>
              <li> For issues or updates please go to our <a href="https://discord.gg/vVZwaQ6Q7N">discord channel</a></li>
            </ul>
          </div>
          <div className="countdownTimer_container">
            <h2> SUPPLY LEFT: </h2>
            <span>{supply}</span>
          </div>
          <div className="mint-btn-container">
            <button className="mint-page-btn" id="walletButton" onClick={connectWalletPressed} >
              {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
              ) : (
                <span>🦊 Connect Wallet</span>
              )}
            </button>
          </div>
          <div className="mint-form-container">
            <div className="mint-form">
              <div className="mint-form-input-cont">
                <input
                  type="number"
                  value={numberInput}
                  className="mint-form-input"
                  onChange={(e) => setNumberInput(Number(e.target.value))}
                />

                <p className="mint-form-info">
                {numberInput <= maxQuantity ? (
                  "Total Cost:  " + String(numberInput * price).substring(0, 4) + " ETH"
                ) : (
                  "Warning! Exceeded Max Mint of " + maxQuantity + " Per Txn"
                )}
                </p>
                <div className="mint-form-input-btns">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      numberInput < maxQuantity && setNumberInput(numberInput + 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mint-form-input-btn"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 11l5-5m0 0l5 5m-5-5v12"
                      />
                    </svg>
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      numberInput > 0 && setNumberInput(numberInput - 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mint-form-input-btn"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 13l-5 5m0 0l-5-5m5 5V6"
                      />
                    </svg>
                  </IconButton>
                </div>
                <button id="mintButton" className="mint-btn" onClick={onMintPressed}>
                  MINT
                </button>
                <p id="status" style={{ color: "red" }}>
                  {status}
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Presale;
