import Home from "./pages/Home";
import Faq from "./pages/Faq";

import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Mint from "./pages/Mint";
import Presale from "./pages/Presale"
import Story from "./pages/Story"


function App() {
  return (
    <div className="App" >
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/terms-and-conditions" component={Terms} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/mint" component={Mint} />
          <Route exact path="/Pr3sale" component={Presale} />
          <Route exact path="/Story" component={Story} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
