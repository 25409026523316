import "./style.css";

export const LandingFront = () => {
  return (
    <div className="landing-front-wrapper">
      <div className="landing-front">
        <p className="newhead">METAVADERS</p>
        <div className="landing-front-text">
          <p className="normal-text">
            Generative collection of unique avatars coming to a
            metaverse near you soon
          </p>
        </div>
        <div className="jointherankcontainer">
          <a href="https://metavaders.io/mint">
            <p className="jointherank">JOIN THE RANKS</p>
          </a>
        </div>
      </div>
    </div>
  );
};
export default LandingFront;
