import AM_01 from "../../assets/Story/AM_01.png";
import AM_02 from "../../assets/Story/AM_02.gif";
import AM_03 from "../../assets/Story/AM_03.png";
import AM_04 from "../../assets/Story/AM_04.png";
import AM_05 from "../../assets/Story/AM_05.png";
import MV_START from "../../assets/Story/MV_start.gif";
import MV_01 from "../../assets/Story/MV_01.png";
import MV_02 from "../../assets/Story/MV_02.gif";
import MV_03 from "../../assets/Story/MV_03.png";
import MV_04 from "../../assets/Story/MV_04.png";
// import MV_05 from "../../assets/Story/MV_05.png";


import NavBar from "../../components/NavBar";
import "./index.css";
import "react-accessible-accordion/dist/fancy-example.css";
import LandingFront from "../../components/LandingFront";

import StoryLineItem from "../../components/StoryLineItem";

import Footer from "../../components/Footer";


function Home() {
  return (
    <div className="Story">
      <div className="container-wrap">
        <NavBar />
        <h2 className="new-section-text">THE INVASION THUS FAR...</h2>
        <div className="storyline-container">
          <div className="storyline-item storyline-item-first">
              <div className="center">
                <img className="storyline-image" src={MV_START} alt="logo" />
              </div>
              <div className="center">
                <p>To keep up to date with our latest invasion of Animetas follow us <a href="https://twitter.com/metavaders">@Metavaders</a></p>
              </div>
          </div>
          <StoryLineItem 
            title="Metavaders HQ -- 2021 Sep 07 @ 12:21" 
            image={MV_01} 
            text="The Legion have given orders to deploy Metavader troops to infiltrate @animetas and conduct reconnaissance, as well as to capture live specimens for research and observation." 
            even_odd="odd"
            />
          <div><span className="downArrow">▼</span></div>
          <StoryLineItem 
            title="Animetas City -- 2021 Sep 07 @ 12:28" 
            image={AM_01}
            text="A viral video of unidentified starships hovering above the city has been circulating recently. Many claim for this to be a hoax, and others speculate for this to be filming for an interesting ad campaign." 
            even_odd="even"
            />
          <div><span className="downArrow">▼</span></div>
          <StoryLineItem 
            title="Metavaders HQ -- 2021 Sep 08 @ 13:55" 
            image={MV_02} 
            text="We have successfully infiltrated the @animetas capital undetected. Teams have been dispatched to seek potential targets of interest marked in our database and further study the environment. The atmosphere is warm, vibrant, and full of resources. And soon, it shall be ours." 
            even_odd="odd"
            />
          <div><span className="downArrow">▼</span></div>

          <StoryLineItem 
            title="Animetas City -- 2021 Sep 08 @ 13:32" 
            image={AM_02}
            text="We have experienced increasing reports of suspicious figures roaming around the neighborhood, with several claiming to have seen unfamiliar robots with glowing red eyes in dark alleys. Citizens are reminded to stay alert at all times and to report any abnormalities to the ACPD." 
            even_odd="even"
            />
          <div><span className="downArrow">▼</span></div>
          <StoryLineItem 
            title="Metavaders HQ -- 2021 Sep 09 @ Unknown" 
            image={MV_03} 
            text="**Psshh*...Report update from the collectors.
            We have successfully captured an 
            @Animetas1
             specimen....along with a monkey. Bringing both back for research. 
            We were met with considerable resistance. Their forces were strong....but not strong enough." 
            even_odd="odd"
            />
          <div><span className="downArrow">▼</span></div>
          <StoryLineItem 
            title="Animetas City -- 2021 Sep 09 @ 15:02" 
            image={AM_03}
            text="Several individuals in distinctly foreign apparel were subdued and taken into custody today. Their intentions are currently unknown, and the ACPD have not disclosed any details." 
            even_odd="even"
            />
          <div><span className="downArrow">▼</span></div>
          <StoryLineItem 
            title="Metavaders HQ -- 2021 Sep 10 @ 3:52" 
            image={MV_04} 
            text='"Broadcast...intercepted successfully.
            "Citizens of Animetas. We are...the Metavaders.
            Provide us with resources, and no harm will be done.
            Resistance is futile. You have been warned."'
            even_odd="odd"
            />
          <div><span className="downArrow">▼</span></div>

          <StoryLineItem 
            title="Animetas City -- 2021 Sep 10  @ 3:52" 
            image={AM_04}
            text="The ACPD have declared a state of emergency, as a hostile group declares their intentions to attack the Animetaverse." 
            even_odd="even"
            />
            <div><span className="downArrow">▼</span></div>

          {/* <StoryLineItem 
            title="Metavaders HQ -- 2021 Sep  @ 13:32" 
            image={MV_05} 
            text="" 
            even_odd="odd"
            />
          <StoryLineItem 
            title="Animetas City -- 2021 Sep  @ 13:32" 
            image={AM_05}
            text="" 
            even_odd="even"
            /> */}
        </div>
        
        <Footer />
      </div>
    </div>
  );
}

export default Home;
