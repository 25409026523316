import lady from "../../assets/ladyavatar.png";
import story from "../../assets/story.gif";
import observation from "../../assets/observation.png";
import logo from "../../assets/logoN.png";
import team_01 from "../../assets/team_01.png";
import team_02 from "../../assets/team_02.png";
import team_03 from "../../assets/team_03.png";
import team_04 from "../../assets/team_04.png";

import Roadmap from "../../components/Roadmap";
import NavBar from "../../components/NavBar";
import LandingFront from "../../components/LandingFront";
import Footer from "../../components/Footer";
import "./index.css";

function Home() {
  return (
    <div className="Home">
      <div className="container-wrap">
        <NavBar />
        <LandingFront />
        <h2 className="new-section-text">THE STORY</h2>
        <div className="twocol-container">
          <div className="twocol-left">
            <img className="imgformetaverse" src={story} alt="logo" />
          </div>
          <div className="twocol-right">
            <p className="normal-text">
              The Metavaders are the last remaining survivors from the
              multiplanetary species, Earth, before it became uninhabitable from
              nuclear warfare and food supply exhaustion. Metavaders wandered
              endlessly in the galaxy before a chance encounter with the Legion,
              a highly technologically advanced species, who offered the
              Metavaders to join their cause - to be uploaded into the Metaverse
              and conquer all the worlds that exist within it. For their
              continued sustainability, the Metavaders invade and capture
              inhabitants of foreign worlds in exchange for resources.
            </p>
          </div>
        </div>
        <div className="onecol-container animetas">
          <p className="invasion-text center">
            They have set their eyes on their first invasion target
            <span className="invasion-target"> the Animetaverse</span>
          </p>
          <p className="normal-text norm-padding">
            The collection is diverse, with a mix of humanoids, alien-race
            species, and the Legion. In addition to a variety of special traits,
            Metavaders will be able to interact with collections from other
            projects with surprising outcomes, and be able to earn special NFTs
            and even monetary rewards (in ETH). In the future, Metavaders will
            also have the ability to combine accessories and companions to evolve
            their Metavaders
          </p>
        </div>
        <h2 className="new-section-text">NEED TO KNOW</h2>
        <div className="twocol-container">
          <div className="twocol-left">
            <img className="img-view-all" src={lady} alt="logo" />
          </div>
          <div className="twocol-right">
            <div className="summary-wrapper">
              <div className="summary-container">
                    <p className="normal-text">
                      <li> What is the total supply? </li>
                      A total of 10101 unique Metavaders will be created <br />
                      <br />
                      <li> How much does it cost to mint a Metavader? </li>
                      0.07 ETH + gas <br />
                      <br />
                      <li>Have more questions?</li>
                      View the full{" "}
                      <a href="/faq" target="_blank" rel="noreferrer" style={{ color: "#FFF" }}>
                        FAQ
                      </a>{" "}
                      or come join us at our Metavaders{" "}
                      <a href="https://discord.gg/FmyYPdt6DX" target="_blank" rel="noreferrer" style={{ color: "#FFF" }}>
                        Discord
                      </a>
                    </p>
                </div>
              </div>
          </div>
        </div>
        <div className="benefits-container">
          <h2 className="new-section-text">BENEFITS TO EARLY METAVADERS</h2>
          <div className="onecol-container benefits">
            <p className="center">
            <ul>
              <li>Exclusive access to all future drops</li>
              <li>▼</li>
              <li>Participation in giveaways and lucky draws</li>
              <li>▼</li>
              <li>Chance to join closed beta testing and sneak peak to exclusive features (such as our Metavaders games and Vader Vault)</li>
              <li>▼</li>
              <li>And much much more as we continue to conquer the Metaverse!</li>
            </ul>
            </p>
          </div>
        </div>
        <div className="onecol-container">
          <img className="img-banner" src={observation} />
        </div>
        <Roadmap />
        <div className="team-container">
          <h2 className="new-section-text">THE TEAM</h2>
          <div className="team-image-container">
            <div className="profile-container">
              <img src={team_01} className="profile-image" />
              <p className="profile-name">PxVader<br /><span>Project Lead</span></p>
            </div>
            <div className="profile-container">
              <img src={team_02} className="profile-image" />
              <p className="profile-name">PxGnome<br /><span>Smart Contract</span></p>
            </div>
            <div className="profile-container">
              <img src={team_03} className="profile-image" />
              <p className="profile-name">PxZoro<br /><span>Website</span></p>
            </div>
            <div className="profile-container">
              <img src={team_04} className="profile-image" />
              <p className="profile-name">INAAN<br /><span>Artist</span></p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
