import React from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
function Terms() {
  return (
    <div>
      <div className="Terms">
        <div className="container-wrap">
          <NavBar />
          <div className="containerformetaverse">
            <div className="topmargin"></div>

            <p className="new-section-text">Terms and Conditions</p>
          </div>
          <div className="terms-content">
            <p className="template-text">
              Metavaders is a collection of digital artworks (NFTs) running on
              the Ethereum network. This website is only an interface allowing
              participants to buy digital collectibles in presale. Users are
              entirely responsible for the safety and management of their own
              private Ethereum wallets and validating all transactions
              andcontracts generated by this website before approval.
              Furthermore, as Metavaders smart contract runs on theEthereum
              network, there is no ability to undo, reverse, or restoreany
              transactions. This website and its connected services are provided
              “as is” and“as available” without a warranty of any kind. By using
              this website, you are accepting sole responsibility for any and
              all transactions involving Metavaders digital collectibles.
            </p>
            <h2 className="new-section-text">OWNERSHIP</h2>
            <h4>- You Own the NFT</h4>
            <p className="template-text">
              Each Metavaders is an NFT on the Ethereum blockchain. When you
              purchase an NFT, you own the underlying Metavaders Token,
              completely. Ownership of the NFT is mediated entirely by the Smart
              Contract and the Ethereum Network: at no point may we seize,
              freeze, or otherwise modify the ownership of any Metavaders Token.
            </p>
            <h4>- Personal Use</h4>
            <p className="template-text">
              Subject to your continued compliance with these Terms, we grant
              you a worldwide, royalty-free license to use, copy, and display
              the purchased Art, along with any extensions that you choose to
              create or use, solely for the following purposes:
              <li> for your own personal, non-commercial use;</li>
              <li>
                as part of a marketplace that permits the purchase and sale of
                your Metavaders Token /NFT, provided that the marketplace
                cryptographically verifies each Metavaders Token owner’s rights
                to display the Art for their Metavaders Token to ensure that
                only the actual owner can display the Art.
              </li>
              <li>
                as part of a third-party website or application that permits
                theinclusion, involvement, or participation of your Metavaders
                Token, provided that the website/application cryptographically
                verifieseach Metavaders Token owner’s rights to display the Art
                for their Metavaders Token to ensure that only the actual owner
                can display the Art, and provided that the Art is no longer
                visible once the owner of Metavaders Token leaves the
                website/application.
              </li>
            </p>

            <h4>- Commercial Use</h4>
            <p className="template-text marginbottom">
              Subject to your continued compliance with these Terms, we grantyou
              an unlimited, worldwide license to use, copy, and display the
              purchased Art for the purpose of creating derivative works
              basedupon the Art (“Commercial Use”). Examples of such
              CommercialUse would e.g., be the use of the Art to produce and
              sell merchandise products (T-Shirts, etc.) displaying copies of
              the Art. For the sake of clarity, nothing in this Section will be
              deemed to restrict you from
              <li>
                owning or operating a marketplace that permits the use and sale
                of Metavaders Token generally, provided that the marketplace
                cryptographically verifies each Metavaders Token owner’s rights
                to display the Art for their Metavaders Token to ensure that
                only the actual owner can display the Art.
              </li>
              <li>
                owning or operating a third-party website or application that
                permits the inclusion, involvement, or participation of
                Metavaders Tokens generally, provided that the third-party
                website or application cryptographically verifies each
                Metavaders Token owner’srights to display the Art for their
                Metavaders Token to ensure that onlythe actual owner can display
                the Art, and provided that the Art is no longer visible once the
                owner of the purchased Metavaders Token leaves the
                website/application;
              </li>
              <li>or earning revenue from any of the foregoing.</li>
            </p>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Terms;
