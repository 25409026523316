import "./style.css";
import TimeLineItem from "../../components/TimeLineItem_v2";

export const Roadmap = ({ number, description }) => {
  return (
  <div id="roadmap">
    <h2 className="new-section-text">LAUNCH ROADMAP</h2>
    <TimeLineItem
      number="25% Minted"
      list={
        ["Submission to Rarity tools and Rarity Sniper listings for Metavaders.",
        "Some of the Metavaders NFTs will be minted and airdropped to our earliest adopters.",
        "Further ramp up of social media and influencer engagement as well as exclusive privileges given to early key community contributors"]
      }
    />
    <TimeLineItem
      number="50% Minted"
      list={
        ["Launch of Metavader dApp showcasing the “Activation” feature",
        "Free giveaway of Animetas NFTs to some Metavader owners to showcase our “Activation” feature",
        "Giveaway of customized 1/1 Metavader Twitter banners"]
      }
    />
    <TimeLineItem
      number="75% Minted"
      list={
        ["Community grant fund of $30,000 to find talented people to supercharge our ecosystem and generate more artwork, content, storyline, etc.",
        "Design for the next community airdrop will begin",
        "Discussion with our community for plans on the next invasion!"]
      }
    />
    <TimeLineItem
      number="100% Minted"
      list={
        ["Begin work on the Vader Vault! A groundbreaking concept of NFT pool where Metavader holders can participate in fractional NFT ownership based upon their contribution.",
        "Development of a “Space Invader” themed mini-game where you will be able to use your actual Metavader for the game!",
        "Cross chain development as we look to expand our reach beyond the Etheruem metaverse!"]
      }
      arrow_hide = "none"
    />

  </div>
  );
};
export default Roadmap;
