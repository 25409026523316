import React, { useState } from "react";
import "./style.css";
import logo from "../../assets/logoN.png";
import { BrowserRouter as Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import twitter from "../../assets/twitter-logo.png";
import medium from "../../assets/medium-logo.png";
import discord from "../../assets/discord-logo.png";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
  paper: {
    background: "red",
  },
});

export const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <div className="navbar">
      <Link to="/">
        <img src={logo} className="logo" alt="logo" />
      </Link>
      <div className="menu">
        <Link to="/mint" className="menu-item">
          Mint
        </Link>
        <a href="/#roadmap" className="menu-item">
          Roadmap
        </a>
        <Link to="/faq" className="menu-item">
          FAQ
        </Link>
        <Link to="/story" className="menu-item">
          Story
        </Link>
      </div>
      <div></div>
      <div className="menu-mobile">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="menu-mobile-svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </Button>
        <Menu
          classes={classes.paper}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <MenuItem onClick={handleClose}>
            {" "}
            <Link to="/" className="menu-item">
              Home
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {" "}
            <a href="mint" className="menu-item">
              Mint
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {" "}
            <a href="#roadmap" className="menu-item">
              Roadmap
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {" "}
            <Link to="/faq" className="menu-item">
              FAQ
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {" "}
            <Link to="/story" className="menu-item">
              Story
            </Link>
          </MenuItem>
        </Menu>
        <a href="https://discord.com/invite/FmyYPdt6DX" target="_blank" rel="noreferrer">
          <img className="navbar-socialicon" src={discord} alt="logo" />
        </a>
        <a href="https://twitter.com/metavaders" target="_blank" rel="noreferrer">
          <img className="navbar-socialicon" src={twitter} alt="logo" />
        </a>
        <a href="https://medium.com/@metavaders" target="_blank" rel="noreferrer">
          <img className="navbar-socialicon" src={medium} alt="logo" />
        </a>
      </div>
      <div className="menu">
        {/* <p className="menu-item">CONNECT</p> */}

        <a href="https://discord.com/invite/FmyYPdt6DX" target="_blank" rel="noreferrer">
          <img className="navbar-socialicon" src={discord} alt="logo" />
        </a>
        <a href="https://twitter.com/metavaders" target="_blank" rel="noreferrer">
          <img className="navbar-socialicon" src={twitter} alt="logo" />
        </a>
        <a href="https://medium.com/@metavaders" target="_blank" rel="noreferrer">
          <img className="navbar-socialicon" src={medium} alt="logo" />
        </a>
      </div>
    </div>
  );
};
export default NavBar;
