import React, { useEffect, useState } from "react";
import "./style.css";

function CountdownTimer({launchDate, title = "Time To Launch:", description="READY TO MINT!"}) {
  const calculateTimeLeft = () => {
    let currentTime = Date.now();
    const difference = launchDate - currentTime;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
    <div className="countdownTimer_container">
      <h2> {timerComponents.length ? title : "SUPPLY LEFT:" } </h2>
      {timerComponents.length ? timerComponents : <span>{description}</span>}
    </div>
  );
}

export default CountdownTimer;