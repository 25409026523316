import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logoN.png";
import twitter from "../../assets/twitter-logo.png";
import medium from "../../assets/medium-logo.png";
import discord from "../../assets/discord-logo.png";
import "./style.css";

function Footer() {
  return (
    <div>
      <div className="onecol-container logo-banner-wrapper">
        <img src={logo} className="logo-banner" alt="logo" />
      </div>
      <div className="footer">
        <div>
        <Link to="./terms-and-conditions">Terms and Conditions</Link>
        </div>
        <div>
        <Link to="./privacy-policy">Privacy Policy</Link>
        </div>
        <div>
            <a href="https://discord.com/invite/FmyYPdt6DX" target="_blank" rel="noreferrer">
              <img className="socialicon" src={discord} alt="logo" />
            </a>
            <a href="https://twitter.com/metavaders" target="_blank" rel="noreferrer">
              <img className="socialicon" src={twitter} alt="logo" />
            </a>
            <a href="https://medium.com/@metavaders" target="_blank" rel="noreferrer">
              <img className="socialicon" src={medium} alt="logo" />
            </a>
          </div>
      </div>
    </div>
  );
}

export default Footer;
