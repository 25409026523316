import "./style.css";
import TimeLineItem from "../TimeLineItem";

export const InvasionPlan = ({ number, description }) => {
  return (
  <div id="invasion_plan">
    <h2 className="new-section-text">ADDITION INFO - INVASION PLAN</h2>
    <TimeLineItem
      number="00"
      description="10101 unique Metavaders are created as we begin gathering our forces for the invasion of the Animetaverse."
    />
    <TimeLineItem
      number="01"
      description="Let the INVASIONS BEGIN! Demonstrate your worth by collecting/capturing your first Animetas to unlock hidden attributes of your Metavader. You will even see their image change as they prepare for battle."
    />
    <TimeLineItem
      number="02"
      description="Vader Vault has been built. Show your fealty and send over your captured Animetas for a position on the Metavader’s Council. You will then be granted special privileges, such as fractional ownership of the NFT’s within the Vader Vault and potentional exclusive airdrops, as well as a permanent change to your Metavader’s Homeearance showing your exemplary status."
    />
    <TimeLineItem
      number="03"
      description="As the invasion progresses, quests and ‘bounty lists’ will be issued to ensure the success of our mission. Metavaders can complete these bounties for special NFTs and monetary rewards (e.g. ETH). Be prepared - some of these will be challenging!"
    />
    <TimeLineItem
      number="04"
      description="As the war rages on, only the strong will survive.Combine the might of your Metavaders to receive stronger and upgraded versions of their former self. We will also be manufacturing new companion NFTs to help fight the Animetad forces."
    />
    <TimeLineItem
      number="05"
      description="After our successful domination of the Animetaverse,we shall prepare to embark on our next invasion and select a different metaverse. All our Metavaders will have the ability to vote to decide on our next metaverse target for conquest. This is a community collaboration effort for metaverse domination!"
    />
    <TimeLineItem
      number="06"
      description="In preparation for embarking on the next world invasion, we will be creating an awesome collection of unique starships for our travels. These starships will be upgradable,and will have different speeds and attributes that will give you an edge over other fellow Metavaders in capturing targets and claiming more prizes."
    />
    <TimeLineItem
      number="07"
      description="Another metaverse invasion begins. Rinse and repeat,until we dominate the ENTIRE metaverse!"
    />
  </div>
  );
};
export default InvasionPlan;
