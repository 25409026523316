import React, { useEffect, useState, loadDataOnlyOnce } from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import CountdownTimer from "../../components/CountdownTimer";
import "./index.css";

import { IconButton } from "@material-ui/core";

//Web3
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  checkSupply,
} from "../../web3/interact.js";


function Mint() {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [numberInput, setNumberInput] = useState(0);
  const [supply, setSupply] = useState("Calibrating");

  const price = 0.075;
  const maxQuantity = 20;

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);

    addWalletListener();
    updateSupply();
  }, []);


  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus('🦊 Connect to Metamask using the "Connect Wallet" button above');
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    const { success, status } = await mintNFT(numberInput, price);
    setStatus(status);
    updateSupply();
  };

  const updateSupply = async () => {
    let result = await checkSupply();
    // result = 0;
    setSupply(result);
  };

  return (
    <div>
      <div className="Mint">
        <div className="container-wrap">
          <NavBar />
          <div className="onecol-container">
            <h2 className="new-section-text">MINT</h2>
            <ul className="mint-ul">
              <li> You will need a Metamask wallet to mint. If you do not have one, you can get it <a href="https://metamask.io/download.html">here</a></li>
              <li> Each mint will cost {price} ETH + gas fees </li>
              <li> {maxQuantity} mints maximum per txn</li>
              <li> For issues or updates please go to our <a href="https://discord.gg/vVZwaQ6Q7N">discord channel</a></li>
            </ul>
          </div>
          {/* <div className="countdownTimer_container">
            <h2> SUPPLY LEFT: </h2>
            <span>{supply}</span>
          </div> */}
          <div className="mint-btn-container">
            <button className="mint-page-btn" id="walletButton" onClick={connectWalletPressed} >
              {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
              ) : (
                <span>🦊 Connect Wallet</span>
              )}
            </button>
          </div>
          <div className="mint-form-container">
            <div className="mint-form">
              <div className="mint-form-input-cont">
                <input
                  type="number"
                  value={numberInput}
                  className="mint-form-input"
                  onChange={(e) => setNumberInput(Number(e.target.value))}
                />

                <p className="mint-form-info">
                {numberInput <= maxQuantity ? (
                  "Total Cost:  " + String(numberInput * price).substring(0, 5) + " ETH"
                ) : (
                  "Warning! Exceeded Max Mint of " + maxQuantity + " Per Txn"
                )}
                </p>
                <div className="mint-form-input-btns">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      numberInput < maxQuantity && setNumberInput(numberInput + 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mint-form-input-btn"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 11l5-5m0 0l5 5m-5-5v12"
                      />
                    </svg>
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      numberInput > 0 && setNumberInput(numberInput - 1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mint-form-input-btn"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 13l-5 5m0 0l-5-5m5 5V6"
                      />
                    </svg>
                  </IconButton>
                </div>
                <button id="mintButton" className="mint-btn" onClick={onMintPressed}>
                  MINT
                </button>
                <p id="status" style={{ color: "red" }}>
                  {status}
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Mint;
